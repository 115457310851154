<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  load: {
    type: Boolean,
    default: true,
    required: false,
  },
  rowOrder: {
    type: Number,
    default: null,
    required: false,
  },
})

const getWidgetName = (type) => {
  return type
}
</script>

<template>
  <!-- <pre style="color: green">
    {{ data }}
  </pre> -->
  <div>
    <component :is="getWidgetName(data.type)" :data="data" :blurColor="data?.blurColor" :rowOrder="rowOrder" />
  </div>
</template>

<style></style>
